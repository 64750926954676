import React from 'react';
import { useRef } from 'react';
import './join.css';
import emailjs from '@emailjs/browser';

const Join = () => {
    const form = useRef();
    
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_n8kzsyw', 'template_4pcays9', form.current, '7kCJKvu-wbGLfkV47')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className='join' id='join-us'>
     <div className='join-l'>
     <hr />
      <div>
       <span className='stroke-text'>ready to</span>
       <span>level up</span>
      </div>
      <div>
      <span>your body</span>
      <span className='stroke-text'>with us</span>
     </div>
     </div>
     <div className='join-r'>
      <form ref={form} className='email-container' onSubmit={sendEmail}>
       <input type='email' name='user_email' placeholder='Enter Your Email adress' />
       <button className='btn btn-j'>Join Us</button>
      </form>
     
     </div>
    </div>
  )
}

export default Join;